window.onload = function () {
    setTimeout(function () {
            if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
                console.log("Navigator incompatible with notifications")
                return;
            } else {
                registerServiceWorker().then(function () {

                    askPermission().then(function () {
                        getNotificationPermissionState().then(function () {
                            subscribeUserToPush().then(function (subscription) {
                                // console.log(subscription);
                                sendSubscriptionToBackEnd(subscription);
                            })
                        })
                    })
                })
            }
        },
        2000
    );
}

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

function getSWRegistration() {
    return navigator.serviceWorker.register('/serviceWorker.js');
}

function registerServiceWorker() {
    return navigator.serviceWorker.register('/serviceWorker.js')
        .then(function (registration) {
            // console.log(registration)
            // console.log('Service worker successfully registered.');
            return registration;
        })
        .catch(function (err) {
            // console.error('Unable to register service worker.', err);
        });
}

function askPermission() {
    return new Promise(function (resolve, reject) {
        const permissionResult = Notification.requestPermission(function (result) {
            resolve(result);
        });

        if (permissionResult) {
            permissionResult.then(resolve, reject);
        }
    })
        .then(function (permissionResult) {
            if (permissionResult !== 'granted') {
                throw new Error('We weren\'t granted permission.');
            }
        });
}

function getNotificationPermissionState() {
    if (navigator.permissions) {
        return navigator.permissions.query({name: 'notifications'})
            .then((result) => {
                return result.state;
            });
    }

    return new Promise((resolve) => {
        resolve(Notification.permission);
    });
}

function subscribeUserToPush() {
    return getSWRegistration()
        .then(function (registration) {
            const subscribeOptions = {
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(
                    pushServerKey
                )
            };
            return registration.pushManager.subscribe(subscribeOptions);

        })
        .then(function (pushSubscription) {
            // console.log('Received PushSubscription: ', JSON.stringify(pushSubscription));
            return pushSubscription;
        });
}

function sendSubscriptionToBackEnd(subscription) {
    console.log(subscription)
    console.log(navigator)
    var postData = {
        subscription: subscription,
        navigator: {
            platform: navigator.platform,
            language: navigator.language,
            appVersion: navigator.appVersion
        }
    };
    var xhr = new XMLHttpRequest();
    xhr.open('POST', suscribeNotification);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(postData));
}
